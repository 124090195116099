<template>
  <div class="content my-page">
    <layoutContainer>
      <el-row :gutter="20" style="margin-top:50px">
        <el-col :span="18">
          <div class="box-card  main-info-box">
            <div style="height:200px;padding-top:5px">
              <el-row>
                <el-col :span="3" class="upload-avada">
                  <el-upload
                    :on-success="uploadCallBack"
                    :headers="{token}"
                    :show-file-list="false"
                    list-type="picture-card"
                    name="picFile"
                    action="/api/file/upload"
                  >
                    <avaderImg
                      v-if="UserInfo.gender !== null"
                      :size="100"
                      :imgUrl="UserInfo.smallHeadpicUrl"
                      :sex="UserInfo.gender == 1 ? true : false"
                    ></avaderImg>
                  </el-upload>
                </el-col>
                <el-col :span="21">
                  <el-form ref="form" :model="form" label-width="120px" size="small" :disabled="disabled">
                    <el-form-item :label="`帐        号 ：`">
                      {{ UserInfo.phonenumber }}
                    </el-form-item>
                    <el-form-item label="昵        称 ：">
                      <span v-if="disabled">{{ form.nickname }}</span>
                      <el-input v-else v-model="form.nickname" style="width:215px"></el-input>
                      <i class="el-icon-edit" style="margin-left:10px" @click="disabled = false"></i>
                    </el-form-item>
                    <el-form-item label="性        别 ：">
                      <span v-if="disabled">{{ form.gender === null ? '' : form.gender ? '男' : '女' }}</span>
                      <el-select v-else v-model="form.gender" placeholder="请选择活动您的性别">
                        <el-option label="男" :value="1"></el-option>
                        <el-option label="女" :value="0"></el-option>
                      </el-select>
                      <el-button
                        v-if="!disabled"
                        round
                        size="mini"
                        type="primary"
                        style="margin-left:10px"
                        @click="sureUpdateUserInfo"
                      >
                        确定
                      </el-button>
                      <el-button v-if="!disabled" round size="mini" type="danger" @click="disabled = true">
                        取消
                      </el-button>
                    </el-form-item>
                  </el-form>
                  <el-form ref="form1" :model="form" label-width="120px" size="small">
                    <el-form-item label="密        码 ：">
                      <el-button
                        type="text"
                        style="color:#FF773A"
                        size="mini"
                        @click="$router.push('/login?submitType=4')"
                      >
                        重设密码
                      </el-button>
                    </el-form-item>
                  </el-form>
                  <el-button class="loginout-button" @click="$router.push('/login')">
                    <img src="/img/tuichu.png" alt="" style="height:12px" /> 退出登录
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card my-enter">
            <div slot="header" class="clearfix">
              <span style="font-weight:600">我的入驻</span>
            </div>
            <div style="height:115px">
              <div>
                <p v-if="!UserInfo.employeeId && !UserInfo.companyId && !UserInfo.teamId">
                  暂未以公司、团队或个人的身份入驻
                </p>
                <el-button
                  style="padding: 15px 0;color:#007AFF"
                  v-if="!UserInfo.employeeId"
                  type="text"
                  @click="$router.push('/enter')"
                >
                  去入驻<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </div>
              <p>
                <el-button
                  style="padding: 15px 0;color:#007AFF"
                  type="text"
                  v-if="UserInfo.companyId"
                  @click="
                    $router.push({
                      path: `/companyDetail/${UserInfo.companyId}`
                    })
                  "
                >
                  我的公司<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </p>

              <p>
                <el-button
                  style="padding: 15px 0;color:#007AFF"
                  type="text"
                  v-if="UserInfo.teamId"
                  @click="
                    $router.push({
                      path: `/teams/detail/${UserInfo.teamId}`
                    })
                  "
                >
                  我的团队<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </p>
              <p>
                <el-button
                  style="padding: 3px 0;color:#007AFF"
                  type="text"
                  v-if="UserInfo.employeeId"
                  @click="
                    $router.push({
                      path: `/personal/detail/${UserInfo.employeeId}`
                    })
                  "
                >
                  我的个人页<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </p>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <!-- <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight:600">我的产品</span>
            </div>

            <div style="height:82px;font-size:13px">
              暂未购买产品
              <el-button
                style="padding: 3px 0;color:#007AFF;margin-left:15px"
                type="text"
                @click="$router.push('/quanqingProduct')"
              >
                去看看<i class="el-icon-arrow-right el-icon--right"></i>
              </el-button>
            </div>
          </el-card> -->
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight:600">我的会员</span>
            </div>

            <div style="height:82px;font-size:13px">
              <div>
                {{
                  vipInfo.hasEffected
                    ? `已是会员，有效期至 ${formatTimeFilter(vipInfo.expirationDate, 'YYYY-MM-DD')}`
                    : '暂不是会员'
                }}
                <p class="get-vip" @click="showVipPage = true">{{ vipInfo.hasEffected ? '续费' : '开通会员' }}</p>
              </div>
              <!-- 暂未购买产品
              <el-button
                style="padding: 3px 0;color:#007AFF;margin-left:15px"
                type="text"
                @click="$router.push('/quanqingProduct')"
              >
                去看看<i class="el-icon-arrow-right el-icon--right"></i>
              </el-button> -->
            </div>
          </el-card>
        </el-col>

        <el-col :span="12">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight:600">引荐赚钱</span>
            </div>
            <div style="height:82px;font-size:13px">
              <center><button class="recommend-button" @click="DialogVisible = true">引荐</button></center>
              <center style="margin-top:15px;">
                <img src="/img/jinbi.png" alt="" style="height:18px; vertical-align: middle;" />
                引荐一位客户，成单后即可获得全倾收入的20%
              </center>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-card class="box-card none-hr">
            <div slot="header" class="clearfix">
              <span style="font-weight:600">入团邀请</span>
            </div>
            <el-button v-if="!UserInfo.employeeId" type="text" @click="$router.push('/enter')">
              个人入驻后，即可被团队邀请，去入驻<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
            <div v-else-if="inviteList.length" v-for="(item, i) in inviteList" :key="i">
              <el-row type="flex" style="padding:10px 0 ">
                <el-col :span="2">
                  <span @click="$router.push({path: `/teams/detail/${item.team.id}`})" class="hoverstyle">
                    {{ item.team.name }}
                  </span>
                </el-col>
                <el-col :span="2" :offset="2">
                  {{ item.team.memberNum }}人 | <i class="el-icon-location-information"></i
                  >{{ item.team.provinceId | AreaListFilter }}
                </el-col>
                <el-col :span="5" :offset="6"> {{ item.inviteTime | formatTimeFilter }}邀请</el-col>
                <el-col :span="5" :offset="2">
                  <el-button @click="employeeInviteAgree(item.team)" type="primary">同意</el-button>
                  <el-button @click="employeeInviteReject(item.team)" type="danger">拒绝</el-button>
                  <el-button @click="employeeInviteDelete(item.team)" type="info">删除</el-button>
                </el-col>
              </el-row>
            </div>
            <el-button v-else type="text" @click="$router.push('/teams')" style="color:#007AFF">
              暂无团队邀请，去看看<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-card>
        </el-col>
      </el-row>
    </layoutContainer>
    <el-dialog :visible.sync="DialogVisible" width="1000px">
      <marketing :submitType="2" :visible.sync="DialogVisible"></marketing>
    </el-dialog>
    <vip :showVipPage="showVipPage" @dialogClose="dialogClose"></vip>
  </div>
</template>

<script>
import marketing from '@/components/marketing'

import {
  getEmployeeInviteListApi,
  employeeInviteAgreeApi,
  employeeInviteRejectApi,
  employeeInviteDeleteApi,
  userModifyInfoApi
} from '../api/personal'
import {checkVip} from '../api/product'
import {formatTimeFilter} from './../utils/index'
import {mapState, mapMutations, mapActions} from 'vuex'
import vip from '../components/vip/index.vue'

export default {
  components: {marketing, vip},
  computed: {
    ...mapState(['UserInfo', 'AreaList', 'BusinessList', 'EducationList', 'EmployeeDomainList', 'EmployeeStationList'])
  },
  data() {
    return {
      showVipPage: false,
      DialogVisible: false,
      disabled: true,
      token: '',
      form: {
        nickname: '',
        gender: '',
        logoPicUrl: '',
        date2: ''
      },
      vipInfo: {},
      inviteList: [
        // {
        //   team: {
        //     id: 1,
        //     name: '腾朗普',
        //     provinceId: 1,
        //     memberNum: 1,
        //     introduction: '132',
        //     settleTime: '2020-10-13T12:41:19.000+00:00',
        //     establishDate: '2020-10-07T16:00:00.000+00:00',
        //     logoPicUrl: '1234',
        //     smallLogoPicUrl: '1234',
        //     status: 1
        //   },
        //   inviteTime: '2020-10-15T14:43:46.000+00:00'
        // }
      ]
    }
  },
  created() {
    this.token = localStorage.getItem('TOKEN')
    this.getEmployeeInviteList()
    this.form.nickname = this.UserInfo.nickname
    this.form.gender = this.UserInfo.gender
    this.form.logoPicUrl = this.UserInfo.smallHeadpicUrl
    this.ChangeUserInfoActions()
    checkVip().then(res => {
      this.vipInfo = res.data
    })
  },
  methods: {
    ...mapMutations(['ChangeUserInfo']),
    ...mapActions(['ChangeUserInfoActions']),
    dialogClose(value) {
      this.showVipPage = false
      if (value) {
        checkVip().then(res => {
          this.vipInfo = res.data
        })
      }
    },
    sureUpdateUserInfo() {
      console.log('submit!')
      this.userModifyInfo()
    },
    formatTimeFilter,
    userModifyInfo() {
      userModifyInfoApi({
        gender: this.form.gender,
        nikeName: this.form.nickname,
        phone: this.UserInfo.phonenumber,
        logoPicUrl: this.form.logoPicUrl
      }).then(res => {
        //TODO: 重新获取个人信息
        this.disabled = true
        this.ChangeUserInfoActions()
      })
    },
    getEmployeeInviteList() {
      getEmployeeInviteListApi({employeeId: this.UserInfo.id})
        .then(res => {
          this.inviteList = [...(res?.data || [])]
        })
        .catch(err => {})
    },
    employeeInviteAgree(item) {
      this.$confirm('确认同意加入此团队吗？').then(() => {
        employeeInviteAgreeApi({teamId: item.id, employeeId: this.UserInfo.id})
          .then(res => {
            if (!res.data) {
              this.$message('您已成功加入团队！')
              this.ChangeUserInfoActions()
            } else {
              res.data && this.$message(res.data)
            }

            this.getEmployeeInviteList()
          })
          .catch(err => {
            this.getEmployeeInviteList()
          })
      })
    },
    employeeInviteReject(item) {
      this.$confirm('确认拒绝加入此团队吗？').then(() => {
        employeeInviteRejectApi({teamId: item.id, employeeId: this.UserInfo.id})
          .then(res => {
            if (!res.data) {
              this.$message('您已拒绝此团队的邀请！')
            } else {
              res.data && this.$message(res.data)
            }
            this.getEmployeeInviteList()
          })
          .catch(err => {
            this.getEmployeeInviteList()
          })
      })
    },
    employeeInviteDelete(item) {
      this.$confirm('确认删除此团队的邀请吗？').then(() => {
        employeeInviteDeleteApi({teamId: item.id, employeeId: this.UserInfo.id})
          .then(res => {
            if (!res.data) {
              this.$message('您已删除此团队的邀请！')
            } else {
              res.data && this.$message(res.data)
            }
            this.getEmployeeInviteList()
          })
          .catch(err => {
            this.getEmployeeInviteList()
          })
      })
    },
    uploadCallBack(res) {
      console.log('res', res)
      if (res.data) {
        this.form.logoPicUrl = res.data
        this.userModifyInfo()
      }
    },
    deletePic() {
      // this.$emit('uploadCallBack', i)
    }
  }
  // TODO: vuex判断是否入驻状态
}
</script>

<style lang="scss">
.my-page {
  /deep/ .el-card__header {
    padding: 12px 20px;
  }
}
.my-enter {
  .el-card__header {
    border: none;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 18px;
      top: auto;
      bottom: 0;
      right: auto;
      height: 1px;
      width: 250px;
      background-color: #efefef;
    }
  }
}

.content {
  /deep/ .el-dialog {
    border-radius: 10px;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  margin: 10px;
}

/* 选项卡*/
.text {
  font-size: 14px;
}
.item {
  margin-bottom: 18px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.box-card {
  // width: 480px;
  height: 100%;
}
/** */

.loginout-button {
  position: absolute;
  right: 20px;
  top: 5px;
}
.upload-avada {
  /deep/ .el-upload--picture-card {
    border: none !important;
    overflow: visible;
  }
  > div {
    position: relative;
    top: -25px;
    // z-index: 999999;
    .el-upload--picture-card {
      background: none;
      border: none;
    }
  }
}

.main-info-box {
  background: #fff;
}

.conten .el-form-item__label {
  white-space: pre;
  color: #999999;
}

.recommend-button {
  width: 140px;
  height: 24px;
  line-height: 24px;
  background: #5ad3cd;
  border-radius: 2px;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  border: none;
  outline: none;
}

.none-hr {
  .el-card__header {
    border: none;
  }
}
.get-vip {
  height: 18px;
  background: linear-gradient(360deg, #ff563f 0%, #ffa13f 100%);
  box-shadow: 0px 2px 4px 0px rgba(255, 146, 102, 0.5);
  border-radius: 9px;
  padding: 0 8px;
  font-size: 10px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  display: inline-block;
  vertical-align: top;
  margin-left: 6px;
  cursor: pointer;
}
</style>

<style>
.hoverstyle:hover {
  color: #5ad3cd !important;
}
/* .el-card {
  border: none;
  overflow: visible;
}
.el-card.is-always-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0);
} */
</style>
