<template lang="pug">
  .page
    layoutContainer
      .order-list(v-for="item in list" :key="item.orderCode")
        .header
          span 订单：{{item.orderCode}}
          span {{item.createTime}}
        //.course-detail(@click="goToClass(item)")
          //span.name {{ item.productBusiness === '会员' ? item.productName : `产品名称：${item.productName}`}}
          //span.price ￥{{item.totalPrice}}
        .course-detail(v-for="(opt,j) in item.items" :key="j")
          span.name {{ item.productBusiness === '会员' ? opt.productName : `产品名称：${opt.productName}`}}
          span.price ￥{{opt.totalPrice}}
        .btn-list
          <!-- 进程状态   展示类型   不可操作    线框   -->
          <!-- 申请被拒绝/退款失败   红线框  -->
          span.apply-reject(v-if="needApply(item) && item.refundApplyStatus === 2") 退款申请已拒绝 
          span.reject-apply(v-if="needApply(item) && item.refundApplyStatus === 0") 退款申请处理中
          <!-- span.reject-apply(v-if="item.payFlag === null") 支付超时 -->
          span.reject-apply(v-if="item.refundStatus === 0") 退款中
          span.reject-apply(v-if="item.refundStatus === 1") 退款成功
          span.apply-reject(v-if="item.refundStatus === 2") 退款失败
          <!-- 可操作都是按钮-->
          <!-- 非视频 需要 申请退款-->
          .relation(v-if="needApply(item) && (item.refundApplyFlag || (item.refundApplyStatus === null && (item.refundStatus === null ||item.refundStatus === 2)))" @click="refund(item)") 申请退款
          <!-- 标准订单可  查看详细  -->
          .relation(v-if="isStandard(item)" @click="goToClass(item)") 查看详细 {{ isStandard(item.productBusiness) }}
          <!-- 视频类订单 可以 去付款、退款-->
          .relation(v-if="isTechVideo(item) && item.refundFlag && (item.refundStatus === null ||item.refundStatus === 2)" @click="refund(item)") 退款
          .relation(v-if="item.payFlag" @click="goPay(item)") 去付款
          <!-- 所有订单都可以  申请开票、联系客服 -->  
          .relation(v-if="item.invoiceApplyFlag" @click="applyInvoice(item)") 申请开票
          .relation(@click="relation") 联系客服
  
          span.total_price 总额
            span.price ￥{{item.totalPrice}}
      el-pagination(background layout="prev, pager, next" :total="total" @current-change="currentChange")
  </template>

<script>
import {orderList, refund} from '@/api/subject'
import {refundApplication} from '@/api/product'
import {applyInvoice} from '@/api/background'

export default {
  name: 'pay',
  // components: {commonHeader},
  data() {
    return {
      list: [],
      page: 1,
      total: 0
    }
  },
  created() {
    this.getList()
  },
  methods: {
    needApply(info) {
      return !!info.productBusiness
    },
    isStandard(info) {
      return info.productBusiness == '标准'
    },
    isTechVideo(info) {
      return !info.productBusiness
    },
    relation() {
      this.$router.push('/connectionQuanqing')
    },
    goToClass(info) {
      if (info.productBusiness === '会员') return
      if (info.productBusiness === '标准') {
        this.$router.push({
          path: '/quanqingStandard',
          query: {
            orderCode: info.orderCode
          }
        })
        return
      }
      this.$router.push({
        path: '/techVideoDetail',
        query: {id: info.productId}
      })
    },
    refund(info) {
      let toastMsg = ''
      if (info.productBusiness === '会员') {
        toastMsg = '申请审核0-2天通过后，预计0-7天内退款成功，并收回对应会员时长。确定申请退款吗？'
      } else if (info.productBusiness === '标准') {
        toastMsg = '确定要申请退款吗？'
      } else {
        toastMsg = '退款后一年内不能再购买此视频，确认要退款么？'
      }
      this.$confirm(toastMsg)
        .then(() => {
          if (info.productBusiness === '会员' || info.productBusiness === '标准') {
            refundApplication({
              orderCode: info.orderCode
            }).then(res => {
              if (res.code === 200) {
                this.$dialog.toast('退款申请已提交，系统处理中，请关注微信/支付宝退款提示', {delay: 4})
                this.getList()
              } else {
                this.$dialog.toast('您的退款申请在等待客服处理中，请耐心等待', {delay: 4})
              }
            })
          } else {
            refund(info.orderCode).then(res => {
              if (res.code === 200) {
                this.$dialog.toast('退款申请已提交，系统处理中，请关注微信/支付宝退款提示', {delay: 4})
                this.getList()
              } else {
                this.$dialog.toast('您的退款申请在等待客服处理中，请耐心等待', {delay: 4})
              }
            })
          }
        })
        .catch(() => {})
    },
    applyInvoice(info) {
      this.$confirm('是否申请开发票？').then(() => {
        applyInvoice({orderCode: info.orderCode}).then(res => {
          if (res.code === 200) {
            this.$dialog.toast('您的开票申请在等待客服处理中，请耐心等待')
            this.getList()
          }
        })
      })
    },
    currentChange(val) {
      this.page = val
      this.getList()
    },
    getList() {
      orderList({
        pageNum: this.page,
        pageSize: 10
      }).then(res => {
        console.log('获取订单信息...res', JSON.stringify(res, null, 2))
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    goPay(item) {
      this.$router.push({
        path: '/pay',
        query: {
          courseId: item.productId,
          orderId: item.orderCode
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100%;
  .order-list {
    max-width: 1200px;
    min-width: 960px;
    padding: 0 30px;
    background: #ffffff;
    border-radius: 8px;
    margin: 20px auto;
  }
  .header {
    height: 107px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 37px;
    }
  }
  .btn-list {
    padding: 30px 0 50px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: red;
      line-height: 40px;
      margin-left: 5px;
    }
    .refund-agreement {
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;
      color: #5ad3cd;
      line-height: 40px;
      text-decoration: underline;
    }
    .apply-reject {
      padding-left: 20px;
      padding-right: 20px;
      line-height: 38px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #ff724c;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff724c;
      text-align: center;
    }
    .reject-apply {
      padding-left: 20px;
      padding-right: 20px;
      height: 40px;
      line-height: 38px;
      border-radius: 20px;
      border: 1px solid #d3d3d3;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      text-align: center;
    }
    .refund-way {
      width: 100px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #d3d3d3;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      text-align: center;
    }
    .apply-refund {
      width: 100px;
      height: 40px;
      background: #ff724c;
      border-radius: 20px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }

    .refund {
      width: 100px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #979797;
      font-size: 18px;
      font-weight: 500;
      color: #666666;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      margin-left: 10px;
    }
    .go-pay {
      width: 100px;
      height: 40px;
      border-radius: 20px;
      background: #ff724c;
      border-radius: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
    }
    .relation {
      width: 100px;
      height: 40px;
      border-radius: 20px;
      background: #5ad3cd;
      border-radius: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .course-detail {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(239, 239, 239, 0.5);
    border-radius: 8px;
    padding: 30px;
    cursor: pointer;
    .name {
      width: 1000px;
      font-size: 24px;
      line-height: 33px;
      margin-right: 40px;
    }
    span {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    .price {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff724c;
    }
  }
}
.total_price {
  padding-left: 15px;
  font-size: 24px !important;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400 !important;
  color: #666666 !important;
  .price {
    font-size: 30px !important;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500 !important;
    color: #ff724c !important;
  }
}
</style>
